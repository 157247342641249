import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { Navbar } from './Navigation/Navbar';
import Footer from './Footer/Footer';
import { Entertainment } from './Pages/Home/Entertainment';
import { EntertainmentPage } from './Pages/Home/EntertainmentPage';
import { NotFound } from './NotFound';

const App = () => {
    return (
        <HelmetProvider>
            <Navbar />
            <div className="content">
                <Routes>
                    <Route path="/" element={<Entertainment />} />
                    <Route path="/entertainmentStage/:language/:actors/:id" element={<EntertainmentPage />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
            <Footer />
        </HelmetProvider>
    );
};

export default App;
