import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Spinner, Alert, Button } from 'react-bootstrap';
import { db } from '../../Firebase'; // Update import path as necessary
import { doc, getDoc } from 'firebase/firestore';
import { FaWhatsapp, FaFacebook, FaInstagram, FaTelegram } from 'react-icons/fa';
import ProductDetails from '../Product/ProductDetails';
import { Helmet } from 'react-helmet-async';

export const EntertainmentPage = () => {
    const { id, language, actors  } = useParams(); 

    const [iframeSrc, setIframeSrc] = useState(null);
    const [downloadIframeSrc, setDownloadIframeSrc] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [overlayVisible, setOverlayVisible] = useState(true);
    const [downloadOverlayVisible, setDownloadOverlayVisible] = useState(true);
    const [movieName, setMovieName] = useState('');
    const [actorName, setActors] = useState('');
    const [adData, setAdData] = useState(null);
    const [adDisplayed, setAdDisplayed] = useState(false);
    const [articleTitle, setArticleTitle] = useState('');
    const [articleHeadTitle, setArticleHeadTitle] = useState('');
    const [articleContent, setArticleContent] = useState('');
    const [articleThemesAndHighlightsTitle, setArticleThemesAndHighlightsTitle] = useState('');
    const [articleThemesAndHighlightsContent, setArticleThemesAndHighlightsContent] = useState('');
    const [articleTechnicalBrillianceTitle, setArticleTechnicalBrillianceTitle] = useState('');
    const [articleTechnicalBrillianceContent, setArticleTechnicalBrillianceContent] = useState('');
    const [articleWhyWatchTitle, setArticleWhyWatchTitle] = useState('');
    const [articleWhyWatchContent, setArticleWhyWatchContent] = useState('');

    const [movies, setMovies] = useState([]);
   
   
   
   


    





    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                console.log('Fetching data for:', language, id);
                const docRef = doc(db, language, id); // Dynamic language and movie ID
                const docSnap = await getDoc(docRef);
    
                if (docSnap.exists()) {
                    const task = docSnap.data();
                    console.log('Movie data:', task); // Log the fetched data
    
                    // Set the fetched data to state
                    setIframeSrc(task.link);
                    setActors(task.actors)
                    setMovieName(task.name || 'Unknown Movie');
                    setArticleTitle(task.articleTitle || '');
                    setArticleHeadTitle(task.articleHeadTitle || '');
                    setArticleContent(task.articleContent || '');
                    setArticleThemesAndHighlightsTitle(task.articleThemesAndHighlightsTitle || '');
                    setArticleThemesAndHighlightsContent(task.articleThemesAndHighlightsContent || '');
                    setArticleTechnicalBrillianceTitle(task.articleTechnicalBrillianceTitle || '');
                    setArticleTechnicalBrillianceContent(task.articleTechnicalBrillianceContent || '');
                    setArticleWhyWatchTitle(task.articleWhyWatchTitle || '');
                    setArticleWhyWatchContent(task.articleWhyWatchContent || '');
                    
    
                    if (task.downloadlink) {
                        setDownloadIframeSrc(task.downloadlink);
                    }
                } else {
                    console.error(`No task found with id: ${id}`);
                    setError("Invalid movie ID or no details available for the selected movie.");
                }
    
                // Fetch advertisement data
                const adDocRef = doc(db, 'ads', 'ad1'); // Update this to your actual ad document path
                const adDocSnap = await getDoc(adDocRef);
                if (adDocSnap.exists()) {
                    const ad = adDocSnap.data();
                    setAdData(ad);
                } else {
                    console.error('No ad data found.');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError("Error fetching data. Please try again later.");
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, [id, language]);
    

    const handleFullScreenClick = () => {
        if (!adDisplayed) {
            window.open('', '_blank'); // Replace with actual ad URL
            setAdDisplayed(true);
        } else {
            setOverlayVisible(false);
        }
    };

 
    const handleDownloadFullScreenClick = () => {
        if (downloadOverlayVisible) {
            window.open('https://brandbuzzads.com/user/video/ZJmnlZ6U', '_blank');
            setDownloadOverlayVisible(false); // Hide the second overlay
        }
    };

    const handleShare = (platform) => {
        const movieLink = window.location.href;

        if (platform === 'whatsapp') {
            window.open(`https://wa.me/?text=${encodeURIComponent(movieLink)}`, '_blank');
        } else if (platform === 'facebook') {
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(movieLink)}`, '_blank');
        } else if (platform === 'telegram') {
            window.open(`https://telegram.me/share/url?url=${encodeURIComponent(movieLink)}&text=${encodeURIComponent("Check out this movie!")}`, '_blank');
        } else if (platform === 'instagram') {
            alert("Instagram doesn't support direct link sharing. Please share the link manually.");
        }
    };

    const headerStyle = {
        color: '#fff',
        textAlign: 'center',
        padding: '2px 0',
        fontFamily: 'Arial, sans-serif',
        marginTop: '-25px',
        background: 'linear-gradient(180deg, #1765f5, #243b55)',
        borderRadius: '8px',
    };

    const iframeStyles = {
        border: 'none',
        width: '100%',
        height: '250px',
    
        overflow: 'hidden',
        cursor: 'pointer',
    };

    const downloadIframeContainerStyles = {
        position: 'relative',
        width: '100%',
        marginTop: '-50px',
        height: '250px',
        overflow: 'hidden',
    };

    const downloadIframeStyles = {
        border: 'none',
        width: '100%',
        height: '100%',
        borderRadius: '8px',
        zIndex: 1,
    };

    const downloadFullScreenStyles = {
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(204, 200, 200, 0.1)',
        cursor: 'pointer',
        color: '#fff',
        fontSize: '20px',
        opacity: downloadOverlayVisible ? 0.1 : 0,
        transition: 'opacity 0.3s ease-in-out',
    };

    const bannerStyles = {
        position: 'absolute',
        top: '50%',
        marginTop: '-15px',
        height: '100px',
        left: '-10px',
        transform: 'translateY(-50%)',
        backgroundColor: 'rgba(255, 255, 255, 9)',
        padding: '10px 20px',
        borderRadius: '5px',
        zIndex: 3,
        textAlign: 'left',
        fontWeight: 'bold',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    };

    const movieNameStyles = {
        fontSize: '1rem',
        fontWeight: 'bold',
        color: '#333',
        backgroundColor: '#f8f9fa',
        padding: '15px 20px',
        borderRadius: '8px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        margin: '10px 0',
        maxWidth: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: '1.5px',
    };

    const articleStyles = {
        margin: '20px 0',
        padding: '15px',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
        color: '#333',
    };

    const articleTitleStyles = {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginBottom: '10px',
    };

    const articleContentStyles = {
        fontSize: '1rem',
        lineHeight: '1.6',
    };

    const handleDownloadClick = () => {
        if (!adDisplayed) {
            const adElement = document.querySelector('.adsbygoogle');
            if (adElement) {
                adElement.click(); 
            }
            setAdDisplayed(true);
        } else {
            alert('You can now download your movie.');
        }
    };

    const AdSenseAd = () => {
        useEffect(() => {
            if (window.adsbygoogle) {
                try {
                    (window.adsbygoogle = window.adsbygoogle || []).push({});
                } catch (e) {
                    console.error('AdSense error', e);
                }
            }
        }, []);

        return (
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <ins
                    className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client="ca-pub-1196895396100273"
                    data-ad-slot="4214645049"
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                ></ins>
            </div>
        );
    };

    return (
        <Container className="mt-4">
                <Helmet>
                       <title>Entertainment - imax1.in</title>
                              <meta name="description" content="Explore the latest movies and entertainment at imax1.in" />
                              <meta name="keywords" content="movies, entertainment, Telugu, imax1" />
                              <link rel='canonical' href='/entertainmentStage/:language/:id'></link>
                          </Helmet>


            

            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" role="status" />
                    <p>Loading data, please wait...</p>
                </div>
            ) : error ? (
                <Alert variant="danger">{error}</Alert>
            ) : (
                <Row className="justify-content-center mb-4">
                    <Col xs={12} md={10} lg={8}>
                    {articleTitle && articleContent && (
    <div style={articleStyles}>
        <div style={articleTitleStyles}>{articleTitle}</div>
        <div style={articleContentStyles}>{articleContent}</div>
    </div>
)}
        

                        <div style={movieNameStyles}>{movieName}</div>

                        <div style={{ position: 'relative', width: '100%', height: '250px' }}>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    width: '100%',
                                    backgroundColor: 'rgba(0, 0, 0, 0.0)',
                                    color: 'rgba(0, 0, 0, 0.0)',
                                    textAlign: 'center',
                                    padding: '15px',
                                    zIndex: 2,
                                }}
                            >
                                🎬 Watch Now: Featured Movie Stream
                            </div>

                            <iframe
                                src={iframeSrc}
                                style={{
                                    ...iframeStyles,
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    width: '100%',
                                    height: '100%',
                                }}
                                title="Movie Stream"
                                allowFullScreen
                                scrolling="yes"
                                onClick={handleFullScreenClick}
                            ></iframe>
                        </div>

                        {articleTitle && (
                        <div style={articleStyles}>
                          <div style={articleTitleStyles}>{articleTitle}</div>
                         <div style={articleContentStyles}>{articleContent}</div>
                          </div>
                         )}
 
{articleThemesAndHighlightsTitle && articleThemesAndHighlightsContent && (
    <div style={articleStyles}>
        <div style={articleTitleStyles}>{articleThemesAndHighlightsTitle}</div>
        <div style={articleContentStyles}>{articleThemesAndHighlightsContent}</div>
    </div>
)}

{articleTechnicalBrillianceTitle && articleTechnicalBrillianceContent && (
    <div style={articleStyles}>
        <div style={articleTitleStyles}>{articleTechnicalBrillianceTitle}</div>
        <div style={articleContentStyles}>{articleTechnicalBrillianceContent}</div>
    </div>
)}

{articleWhyWatchTitle && articleWhyWatchContent && (
    <div style={articleStyles}>
        <div style={articleTitleStyles}>{articleWhyWatchTitle}</div>
        <div style={articleContentStyles}>{articleWhyWatchContent}</div>
    </div>
)}

                 
                      
                        <Row className="justify-content-center mb-4">
                        <Col xs={12} md={10} lg={8}>
                            <div style={downloadIframeContainerStyles}>
                                {downloadIframeSrc ? (
                                    <>
                                        <iframe
                                            src={downloadIframeSrc}
                                            style={downloadIframeStyles}
                                            title="Movie Download"
                                            scrolling="no"
                                        ></iframe>
                                        {downloadOverlayVisible && (
                                            <div
                                                style={downloadFullScreenStyles}
                                                onClick={handleDownloadFullScreenClick}
                                            ></div>
                                        )}
                                        <div style={bannerStyles}>Download Your Movie Here!</div>
                                    </>
                                ) : (
                                    <div style={{ textAlign: 'center', padding: '20px' }}>
                                        <strong>Download link not available</strong>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>

                    </Col>
                </Row>
            )}
            <Container className="mt-4">
            


            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" role="status" />
                    <p>Loading movies, please wait...</p>
                </div>
            ) : error ? (
                <Alert variant="danger">{error}</Alert>
            ) : (
                <Row>
                  
                </Row>
            )}
        </Container>



            
        </Container>
    );
};

export default EntertainmentPage;
