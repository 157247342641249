import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../Firebase';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import 'bootstrap/dist/css/bootstrap.min.css';

function ProductList() {
    const [products, setProducts] = useState([]);
    const [startIndex, setStartIndex] = useState(2); // Start at index 2
    const scrollRef = useRef(null); // Reference for the scroll container

    useEffect(() => {
        fetchProducts(); // Fetch products when the component mounts
        let scrollInterval;

        // Set up the automatic scroll change every 5 seconds
        if (products.length > 2) {
            scrollInterval = setInterval(() => {
                // Update the startIndex to show the next set of 2 products
                setStartIndex((prevIndex) => {
                    const nextIndex = prevIndex + 2;
                    return nextIndex < products.length ? nextIndex : 0; // Reset to 0 if the end is reached
                });
            }, 5000); // Every 5 seconds
        }

        // Clean up interval when component unmounts
        return () => clearInterval(scrollInterval);
    }, [products]); // Run when products change

    const fetchProducts = async () => {
        try {
            const productsQuery = query(collection(db, 'product'), orderBy('link'));
            const productsSnapshot = await getDocs(productsQuery);
            const fetchedProducts = productsSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            console.log('Fetched Products:', fetchedProducts); // Log the fetched data

            setProducts(fetchedProducts); // Set the fetched products into state
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const renderSideScroll = (startIndex) => {
        return (
            <div
                ref={scrollRef} // Assign the ref to the scroll container
                style={{
                    display: 'flex',
                    overflowX: 'auto', // Enable horizontal scroll
                    padding: '10px 0',
                    whiteSpace: 'nowrap', // Prevent wrapping of items
                    marginBottom: '20px',
                }}
            >
                {products.slice(startIndex, startIndex + 2).map((product) => (
                    <div key={product.id} style={{ flex: '0 0 auto', width: '150px' }} className="mx-2">
                        <div className="card">
                            <a href={product.link} target="_blank" rel="noopener noreferrer">
                                <img
                                    src={product.url || 'path/to/placeholder.jpg'}
                                    alt="Product"
                                    className="card-img-top"
                                    style={{
                                        height: '80px',
                                        width: '80px',
                                        objectFit: 'cover',
                                    }}
                                />
                                <div style={{ textAlign: 'center' }}>
                                    ${product.cost}
                                </div>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="container mt-5">
          

            {products.length === 0 ? (
                <p>No products available.</p>
            ) : (
                <>
                    {/* Render the first 6 items in grid format */}
                    <div className="row mb-4">
                        {products.slice(0, 6).map((product) => (
                            <div key={product.id} className="col-6">
                                {/* Two images per row */}
                                <div className="card mb-3">
                                    <a href={product.link} target="_blank" rel="noopener noreferrer">
                                        <img
                                            src={product.url || 'path/to/placeholder.jpg'}
                                            alt="Product"
                                            className="card-img-top"
                                            style={{
                                                height: '100px',
                                                width: '100px',
                                                
                                            }}
                                        />
                                        <div style={{ textAlign: 'center' }}>
                                            ${product.cost}
                                        </div>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Render the side-scrolling for the next set of products every 5 seconds */}
                    {renderSideScroll(startIndex)}
                </>
            )}
        </div>
    );
}

export default ProductList;
